import React from "react";
import styled from "styled-components";

const StyledIntro = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const PageIntro = ({ heading, subheading }) => {
  return (
    <StyledIntro>
      <h1 style={{ color: "#286544" }}>{heading}</h1>
      <h2 style={{ color: "#5B5B5B" }}>{subheading}</h2>
    </StyledIntro>
  );
};

export default PageIntro;
