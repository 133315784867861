import React, { useState, useEffect } from "react";
import styled from "styled-components";

const WhatsappButtonStyles = styled.a`
  width: 180px;
  height: 50px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity 0.35s;
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;

  @media screen and (min-width: 768px) {
    display: none;
  }

  .text {
    background-color: #286544;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    position: relative;

    &:after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(40, 101, 68, 0);
      border-left-color: #286544;
      border-width: 8px;
      margin-top: -8px;
    }
  }
`;

const FloatingWhatsapp = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 150) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <WhatsappButtonStyles show={isVisible} href="https://wa.me/447541081857">
      <div className="text">WhatsApp us</div>

      <img
        src="/icons8-whatsapp-144.png"
        alt="Cardiff Garden Rooms whatsapp"
        width={48}
        height={48}
      />
    </WhatsappButtonStyles>
  );
};

export default FloatingWhatsapp;
