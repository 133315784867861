import React from "react";
import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles.js";
import Header from "../components/Header.js";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import PageIntro from "../components/PageIntro.js";
import Contact from "../sections/Contact.js";
import FloatingWhatsapp from "../components/FloatingWhatsapp.js";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const StyledArticles = styled.article`
  display: flex;
  max-height: 400px;
  background-color: rgb(225, 233, 229);
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
    max-height: 250px;
  }

  .content {
    width: 100%;
    padding: 24px;

    @media (min-width: 768px) {
      width: 50%;
    }
  }

  img {
    width: 100%;
    height: 300px;
    position: relative;
    object-fit: cover;

    @media (min-width: 768px) {
      width: 50%;
      height: auto;
    }
  }
`;

const blog = () => {
  const data = useStaticQuery(graphql`
    query BlogIndexQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/blog/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        nodes {
          id
          excerpt(pruneLength: 150)
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            featured_image
          }
          fields {
            slug
          }
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyles />

      <Header hero={false} />

      <section id="intro">
        <div className="container">
          <PageIntro subheading="Latest news and updates from Cardiff Garden Rooms" />
          {data.allMarkdownRemark.nodes.map((node) => (
            <StyledArticles key={node.id}>
              <div className="content">
                <h3>{node.frontmatter.title}</h3>
                <p>{node.excerpt}</p>
                <Link
                  to={node.fields.slug}
                  className="btn"
                  style={{ display: "inline-block", margin: "10px auto 0" }}
                >
                  Read More
                </Link>
              </div>

              <img src={"/" + node.frontmatter.featured_image} />
            </StyledArticles>
          ))}
        </div>
      </section>

      <Contact inverted={true} />
      <FloatingWhatsapp />
    </>
  );
};

export default blog;
